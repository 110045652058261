import { BaseModel } from '@/_models/base'
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
export class LocationTimeSlot extends BaseModel {
  constructor(id, locationId, weekday, openFrom, openUntil, maxDateCount, busy) {
    super()

    this.id = id
    this.locationId = locationId
    this.weekday = weekday
    this.openFrom = openFrom
    this.openUntil = openUntil
    this.maxDateCount = maxDateCount
    this.busy = busy

    this.defaultRoute = 'partner-portal/location-time-slots'
  }

  /**
     * Receives json and returns a TimeSlot object
     * @param json
     * @returns LocationTimeSlot
     */
  fromJSON(json) {
    const timeSlot = Object.assign(new LocationTimeSlot(), json)
    timeSlot.parseBaseJSON()
    return timeSlot
  }
}

export const cLocationTimeSlot = new LocationTimeSlot()
