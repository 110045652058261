/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { BaseModel } from '@/_models/base'
import { requests } from '@/_helpers/requests'
import { cLocationAvailabilityOverride } from '@/_models/location-availability-override'
import { cPhoto, cLocationTimeSlot, cDeal } from './index'

export class Location extends BaseModel {
  constructor(id, partnerId, contact, contactId, city, cityId, cityName, deals, name, postalCode, country, address, phone, email,
    lat, lon, companyType, website, rating, photo, photoId, needsInvoice, timeSlots, approveStatus,
    approvedAt, approveNote, dealBlob, authToken, reservationMethod, reservationInfo, formitableId,
    description, tags, display, availabilityOverrides) {
    super()

    this.id = id
    this.partnerId = partnerId
    this.contact = contact
    this.contactId = contactId
    this.city = city
    this.cityId = cityId
    this.cityName = cityName
    this.deals = deals
    this.name = name
    this.postalCode = postalCode
    this.country = country
    this.address = address
    this.phone = phone
    this.email = email
    this.lat = lat
    this.lon = lon
    this.companyType = companyType
    this.website = website
    this.rating = rating
    this.photo = photo
    this.photoId = photoId
    this.needsInvoice = needsInvoice
    this.timeSlots = timeSlots
    this.approveStatus = approveStatus
    this.approvedAt = approvedAt
    this.approveNote = approveNote
    this.dealBlob = dealBlob
    this.authToken = authToken
    this.reservationMethod = reservationMethod
    this.reservationInfo = reservationInfo
    this.formitableId = formitableId
    this.description = description
    this.tags = tags
    this.display = display
    this.availabilityOverrides = availabilityOverrides

    this.defaultRoute = 'partner-portal/locations'
  }

  getLastOnline() {
    return 'To be implemented'
  }

  fromJSON(json) {
    const location = Object.assign(new Location(), json)
    location.parseBaseJSON()
    location.photo = json.photo ? cPhoto.fromJSON(json.photo) : null
    location.partnerDeals = (json.dealBlob) ? JSON.parse(atob(json.dealBlob)) : []
    location.timeSlots = cLocationTimeSlot.fromJsonList(json.timeSlots)
    location.availabilityOverrides = cLocationAvailabilityOverride.fromJsonList(json.availabilityOverrides)
    location.deals = cDeal.fromJsonList(json.deals)
    if (location.reservationPreference === '') {
      location.reservationPreference = 'unknown'
    }
    return location
  }

  save() {
    const data = { ...this }
    data.dealBlob = btoa(JSON.stringify(this.partnerDeals))
    return super.save(data)
  }

  getReviews(from, until, sortOrder, filteredTags, withReview) {
    return requests.post(`partner-portal/locations/${this.id}/reviews`, {
      from, until, sort: sortOrder, filteredTags, withReview,
    })
  }
}

export const cLocation = new Location()
