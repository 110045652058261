/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable new-cap */
/* eslint-disable consistent-return */
/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { requests } from '@/_helpers/requests'

export class BaseModel {
  constructor() {
    this.id = null
    this.defaultRoute = ''
  }

  getAll() {
    return new Promise((resolve, reject) => {
      requests.get(this.getMethod('list')).then(response => {
        if (response.data == null) {
          return []
        }
        const list = response.data.map(value => this.fromJSON(value))
        resolve(list)
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }

  /**
     * Returns an object for the given id
     * @param id
     * @returns {Promise<unknown>}
     */
  getOne(id) {
    return new Promise((resolve, reject) => {
      requests.get(this.getMethod('one').replace(':id', id)).then(response => {
        const item = this.fromJSON(response.data)
        resolve(item)
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }

  getFiltered(limit, page, filters, orderings, unique) {
    const params = this.constructor.setParams(limit, page, orderings, unique)

    if (filters && filters.size > 0) {
      params.filters = {}
      filters.forEach((item, key) => {
        params.filters[key] = item
      })
    }

    return new Promise((resolve, reject) => {
      const method = this.getMethod('filtered')
      if (typeof method === 'undefined') {
        resolve({ data: [], total: 0 })
        return
      }
      requests.post(method, params).then(response => {
        if (response.data == null) {
          resolve({ data: [], total: 0 })
          return
        }
        const list = response.data.list.map(value => this.fromJSON(value))
        resolve({ list, total: response.data.total })
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }

  getOrdered(query_params) {
    const params = {}
    query_params.forEach((item, key) => {
      params[key] = item
    })

    return new Promise((resolve, reject) => {
      requests.post(this.getMethod('ordered'), params).then(response => {
        if (response.data == null) {
          return []
        }
        const list = response.data.map(value => this.fromJSON(value))
        resolve(list)
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }

  /**
     * Fill parameters using for backend pagination
     * @param limit
     * @param page
     * @param orderings
     * @param unique
     * @returns {{}}
     */
  static setParams(limit, page, orderings, unique) {
    const params = {}
    if (limit) {
      params.limit = limit
    }
    if (page) {
      params.page = page
    }
    if (unique) {
      params.unique = unique
    }
    if (orderings && orderings.size > 0) {
      params.orderings = {}
      orderings.forEach((item, key) => {
        params.orderings[key] = item
      })
    }
    return params
  }

  /**
     * Performs a get for given url and parses the response
     * @param url
     * @returns {Promise<unknown>}
     */
  static get(url) {
    return new Promise((resolve, reject) => {
      requests.get(url).then(response => {
        resolve(response.data)
      }, error => {
        window.console.log(error)
        reject(error)
      })
    })
  }

  /**
     * Gets json and returns a model of given class
     * @param json
     * @returns BaseModel
     */
  fromJSON(json) {
    return Object.assign(new this.constructor.name(), json)
  }

  /**
     * Gets a list of json data and returns a list of objects for given class
     * @param list
     * @returns {[]|*[]}
     */
  fromJsonList(list) {
    const result = []
    if (list === null || typeof list === 'undefined') {
      return []
    }
    list.forEach(json => {
      result.push(this.fromJSON(json))
    })
    return result
  }

    static modelName = 'base';

    static modelNamePlural = 'bases';

    withDefaultMethods(methods) {
      if (this.defaultRoute === '') {
        return methods
      }
      return {
        filtered: `admin/${this.defaultRoute}/search`,
        detailed: `${this.defaultRoute}/:id/complete`,
        list: `${this.defaultRoute}`,
        create: `${this.defaultRoute}`,
        one: `${this.defaultRoute}/:id`,
        delete: `${this.defaultRoute}/:id`,
        update: `${this.defaultRoute}/:id`,
        ...methods,
      }
    }

    methods() {
      return this.withDefaultMethods({})
    }

    getMethod(method) {
      return this.methods()[method]
    }

    getDetailed(id) {
      return new Promise((resolve, reject) => {
        requests.get(this.getMethod('detailed').replace(':id', id)).then(response => {
          const obj = this.fromJSON(response.data)
          resolve(obj)
        }, error => {
          window.console.log(error)
          reject(error)
        })
      })
    }

    deleteThis() {
      if (this.id != null) {
        this.deletedAt = new Date()
        return requests.deleteReq(this.getMethod('delete').replace(':id', this.id))
      }
    }

    update(data) {
      if (!data) {
        data = this
      }
      return requests.put(this.getMethod('update').replace(':id', this.id), data)
    }

    create() {
      return new Promise((resolve, reject) => {
        requests.post(this.getMethod('create'), this).then(response => {
          this.id = response.data.id
          resolve()
        }, error => {
          window.console.log(error)
          reject(error)
        })
      })
    }

    save() {
      if (this.id !== null && this.id !== '-1' && typeof this.id !== 'undefined') {
        return this.update()
      }
      return this.create()
    }

    static getList(state) {
      if (!(this.modelNamePlural in state)) {
        return []
      }
      return state[this.modelNamePlural].list
    }

    static getFromId(id, state, returnDefault) {
      if (id == null) {
        if (returnDefault) {
          return { name: 'Not set' }
        }
        return null
      }

      if (!(this.modelNamePlural in state)) {
        return new this()
      }

      const item = state[this.modelNamePlural].list.find(e => e.id === id)
      if (item != null) {
        return item
      }

      return new this()
    }

    parseBaseJSON() {
      this.createdAt = new Date(this.createdAt)
      this.updatedAt = new Date(this.updatedAt)
      if (this.deletedAt != null) {
        this.deletedAt = new Date(this.deletedAt)
      } else {
        this.deletedAt = null
      }
    }

    idRoute() {
      return `${this.defaultRoute}/${this.id}`
    }
}

export const cBase = new BaseModel()
